/* global gettext */

const BASE_COUNT = 10
let COUNT_SLIDER_ITEMS = BASE_COUNT
let COUNT_EPISODE = 0

// This gettext must be here due to command make check-i18n fails.
// Reason is that xgettext is not able to compile them from template literars.
const episodesTrans = gettext('Episodes')
const untilTrans = gettext('until')
const episodeTrans = gettext('Episode')


const get_tab_episodes = (serial) => {
    const tab_episode = []

    for (const episode of serial.querySelectorAll(`.newsblog-serial > .episode`)) {
        if (tab_episode.length < BASE_COUNT) {
            tab_episode.push(episode)
        }
    }
    return tab_episode
}


const nav_item_dom = (serial) => {
    serial.querySelector('.nav.nav-tabs').insertAdjacentHTML('beforeend', `
        <li class="nav-item">
            <a href="#tab-${COUNT_SLIDER_ITEMS}" class="nav-link"
                id="tab-label-${COUNT_SLIDER_ITEMS}" data-toggle="tab"
                aria-controls="tab-${COUNT_SLIDER_ITEMS}"
                aria-selected="false" role="tab">
            </a>
        </li>
    `)

    if (COUNT_SLIDER_ITEMS === BASE_COUNT) {
        serial.querySelector(`#tab-label-${COUNT_SLIDER_ITEMS}`).ariaSelected = 'true'
        serial.querySelector(`#tab-label-${COUNT_SLIDER_ITEMS}`).classList.add('active')
    }
}


const tab_content_dom = (serial, episodes) => {
    const episode_numbers = []

    serial.querySelector('.tab-content').insertAdjacentHTML('beforeend', `
        <div class="tab-pane owl-carousel owl-theme"
            id="tab-${COUNT_SLIDER_ITEMS}"
            aria-labelledby="tab-label-${COUNT_SLIDER_ITEMS}"role="tabpanel">
        </div>
    `)

    COUNT_SLIDER_ITEMS === BASE_COUNT
        ? serial.querySelector(`#tab-${COUNT_SLIDER_ITEMS}`).classList.add('active')
        : null

    episodes.forEach(episod => {
        serial.querySelector('.tab-content .tab-pane:last-of-type').appendChild(episod)
        episode_numbers.push(~~episod.dataset.episode_position)
    })

    const episode_min = Math.min(...episode_numbers)
    const episode_max = Math.max(...episode_numbers)
    const tab_text = episode_min === episode_max
        ? `${episodeTrans} ${episode_min}`
        : `${episodesTrans} ${episode_min} ${untilTrans} ${episode_max}`

    serial.querySelector(`#tab-label-${COUNT_SLIDER_ITEMS}`).textContent =
        `${tab_text}`

    setTimeout(() => {
        if (episode_numbers.length < 5) {
            serial.querySelector(`#tab-${COUNT_SLIDER_ITEMS} .owl-nav`).classList.add('d-none')
        }
    }, 100)
}


const sort_episodes = (serial) => {
    const episodes = Array.from(serial.getElementsByClassName('episode'))
    episodes.sort((a, b) => a.dataset.episode_position - b.dataset.episode_position)
    for (let i = 0, len = episodes.length; i < len; i++) {
        const parent = episodes[i].parentNode
        const detatchedEpisode = parent.removeChild(episodes[i])
        parent.appendChild(detatchedEpisode)
    }
}


export const init_serial = (serial) => {
    COUNT_EPISODE = ~~serial.dataset.episode_total - 1

    if (COUNT_EPISODE < 1) {
        return serial.remove()
    }

    if (!serial.querySelector('.tab-content')) {
        sort_episodes(serial)
    }

    const episodes = get_tab_episodes(serial)

    if (!serial.querySelector('.nav.nav-tabs')) {
        serial.insertAdjacentHTML('afterbegin', `
        <ul class="nav nav-tabs" role="tablist">
        </ul>
        <div class="tab-content">
        </div>
        `)
    }

    nav_item_dom(serial)
    tab_content_dom(serial, episodes)

    if (COUNT_SLIDER_ITEMS < COUNT_EPISODE) {
        COUNT_SLIDER_ITEMS = COUNT_SLIDER_ITEMS + BASE_COUNT
        init_serial(serial)
    }
}
