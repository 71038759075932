const SEARCH_TEXT_MIN = 2


function search_input_hide() {
    // hide search input
    document.getElementById('search-wrapper').style.display = 'none'
}


function search_input_show_and_focus() {
    document.getElementById('search-wrapper').style.display = 'flex'
    document.getElementById('search-input').focus()
}


export function search_icon_click() {
    // Click handler for search icon.
    if (document.getElementById('search-wrapper').style.display === 'flex') {
        if (document.getElementById('search-input').value.length >= SEARCH_TEXT_MIN) {
            // submit form
            document.getElementById('aldryn-search').submit()
        } else {
            // hide search input
            search_input_hide()
        }
    } else {
        // display search input
        search_input_show_and_focus()
    }
}


export function search_input_keyup(event) {
    if (event.key === "Escape") {
        search_input_hide()
        return
    }
}


export function aldryn_search_submit(event) {
    // Do not submit search form when text in search input is lower than minimum.
    if (document.getElementById('search-input').value.length < SEARCH_TEXT_MIN) {
        event.preventDefault()
    }
}
